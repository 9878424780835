import React from 'react';
import { Box, Image, Title, Container, Text, Button } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import PageTitle from '../../components/PageTitle/PageTitle';

// images
import Avatar01 from '../../assets/images/avatar01.png';
import AppScanner from '../../assets/images/app-scanner.png';
import AppStore from '../../assets/images/app-store.png';
import playStore from '../../assets/images/play-store.png';

const BookingSuccessful = () => {
  const location = useLocation();
  const params = useParams();
  // console.log('location', location);
  // console.log('params', params);
  const machineUUID = localStorage.getItem('machineUUID');
  const isAuth = localStorage.getItem('isAuth');
  // const { court_nos, date, total_expected_cost, sports_start_time, sports_start_bookingAmPm, sports_end_time, uuid } =
  //   location.state;
  return (
    <Container size="md" px="md">
      <PageTitle uuid="Booking" />
      <Box
        sx={() => ({
          background: '#252a3d',
          padding: '60px 110px',
          borderRadius: '15px',
          '@media only screen and (max-width: 900px)': {
            padding: '60px',
          },
          '@media only screen and (max-width: 700px)': {
            padding: '30px',
          },
          '@media only screen and (max-width: 480px)': {
            padding: '15px',
          },
        })}
      >
        <Title
          order={6}
          sx={() => ({
            fontSize: 40,
            fontWeight: 500,
            lineHeight: 0.5,
            color: '#2aa76c',
            textAlign: 'center',
            '@media only screen and (max-width: 480px)': {
              fontSize: 30,
              lineHeight: '35px',
            },
          })}
        >
          Booking Successful
        </Title>
        <CircleCheck
          style={{
            height: 112,
            width: 122,
            fontWeight: 400,
            color: '#2aa76c',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '40px auto',
          }}
        />
        {/* <Box 
        sx={() => ({
          display: 'flex',
          justifyContent: 'space-between',
          margin: '30px 0',
          "@media only screen and (max-width: 900px)": {
            flexDirection: 'column',
            gap: 15
          }
        })}
      >
        <Text 
          sx={() => ({
            fontSize: 25,
            lineHeight: 1,
            color: '#b2b2b2',
            "@media only screen and (max-width: 480px)": {
              fontSize: 18
            }
        })}>
          Time
        </Text>
        <Text
          sx={() => ({
            fontSize: 25,
            color: '#ededed',
            lineHeight: '30px',
            "@media only screen and (max-width: 480px)": {
              fontSize: 18
            }
          })}
        >
          {moment(date).format("Do MMM YYYY")}{','}{sports_start_time}{sports_start_bookingAmPm}{'-'}{sports_end_time}
        </Text>
      </Box> 
      <Box
        sx={() => ({
          display: 'flex',
          justifyContent: 'space-between',
          margin: '30px 0',
          "@media only screen and (max-width: 900px)": {
            flexDirection: 'column',
            gap: 15
          }
        })}
      >
        <Text
        sx={() => ({
            fontSize: 25,
            lineHeight: 1,
            color: '#b2b2b2',
            "@media only screen and (max-width: 480px)": {
              fontSize: 18
            }
          })}
        >
          Courts
        </Text>
        <Text 
        sx={() => ({
          fontSize: 25,
          lineHeight: 1,
          color: '#ededed',
          "@media only screen and (max-width: 480px)": {
            fontSize: 18
          }
        })}
        >
         {court_nos?.map(courtItem => <span key={courtItem}> {courtItem}, </span>)}
        </Text>
      </Box> 
      <Box
        sx={() => ({
          display: 'flex',
          justifyContent: 'space-between',
          margin: '15px 0 25px 0',
          paddingBottom: 40,
          borderBottom: '1px solid #434343',
          "@media only screen and (max-width: 900px)": {
            flexDirection: 'column',
            gap: 15
          }
        })}
      >
        <Text style={{
        
        }}
        sx={() => ({
          fontSize: 25,
          lineHeight: 1,
          color: '#b2b2b2',
          "@media only screen and (max-width: 480px)": {
            fontSize: 18
          }
        })}
        >
          Price
        </Text>
        <Text
        sx={() => ({
          fontSize: 25,
          lineHeight: 1,
          color: '#ededed',
          "@media only screen and (max-width: 480px)": {
            fontSize: 18
          }
        })}
        >
          {`RM${total_expected_cost}`}
        </Text>
      </Box> */}
        <Text
          sx={() => ({
            fontSize: 25,
            fontWeight: 300,
            color: '#ffffff',
            textAlign: 'center',
            '@media only screen and (max-width: 480px)': {
              fontSize: 18,
            },
          })}
        >
          Receipt successfully sent to your email address
        </Text>
        <Text
          style={{
            fontSize: 20,
            fontWeight: 300,
            textAlign: 'center',
            marginTop: 20,
          }}
        >
          Contact{' '}
          <span
            style={{
              color: '#b42b2d',
            }}
          >
            016 293 0845
          </span>{' '}
          for Assistance
        </Text>
        <Box
          sx={() => ({
            padding: 50,
            borderRadius: 15,
            backgroundColor: 'rgba(255, 255, 255, 0.85)',
            marginTop: 35,
            '@media only screen and (max-width: 480px)': {
              padding: 15,
            },
          })}
        >
          <Title
            order={6}
            sx={() => ({
              fontSize: 30,
              fontWeight: 500,
              lineHeight: '35px',
              color: '#1a282f',
              marginBottom: 25,
              '@media only screen and (max-width: 480px)': {
                fontSize: 22,
              },
            })}
          >
            Here&apos;s what you&apos;re missing out!
          </Title>
          <Text
            style={{
              fontSize: '20',
              lineHeight: 1.45,
              color: '#3d3d3d',
              margin: '15px 0',
            }}
          >
            1. Download and Sign Up as an AFA User today so you&apos;re able to collect loyalty points and redeem up to
            RM30 discount!
          </Text>
          <Text
            style={{
              fontSize: '20',
              lineHeight: 1.45,
              color: '#3d3d3d',
              margin: '15px 0',
            }}
          >
            2. Stay up to date with our app notifications to receive awesome rewards from time to time!
          </Text>
          <Text
            style={{
              fontSize: '20',
              lineHeight: 1.45,
              color: '#3d3d3d',
              margin: '15px 0',
            }}
          >
            3. Make bookings anywhere and anytime right at your fingertips!
          </Text>
          <Box
            sx={() => ({
              display: 'flex',
              alignItems: 'end',
              '@media only screen and (max-width: 900px)': {
                flexDirection: 'column',
                gap: 50,
                alignItems: 'center',
              },
            })}
          >
            <Box
              style={{
                flex: '1 1 50%',
                maxWidth: '50%',
              }}
            >
              <Image
                src={Avatar01}
                alt=""
                style={{
                  maxWidth: 230,
                }}
              />
            </Box>
            <Box
              style={{}}
              sx={() => ({
                flex: '1 1 50%',
                maxWidth: '50%',
                display: 'flex',
                alignItems: 'end',
                '@media only screen and (max-width: 900px)': {
                  alignItems: 'center',
                  maxWidth: '100%',
                },
                '@media only screen and (max-width: 400px)': {
                  flexDirection: 'column',
                  gap: 25,
                },
              })}
            >
              <Link to="/" target="_blank">
                <Image
                  style={{
                    width: '110px',
                    margin: '0 10px',
                  }}
                  src={playStore}
                  alt=""
                />
              </Link>
              <Link to="/" target="_blank">
                <Image
                  style={{
                    width: '110px',
                    margin: '0 10px',
                  }}
                  src={AppStore}
                  alt=""
                />
              </Link>
            </Box>
            <Box>
              <Image
                style={{
                  maxWidth: 290,
                }}
                src={AppScanner}
                alt=""
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Button
        sx={() => ({
          padding: '15px 50px',
          margin: '60px auto 35px auto',
          minHeight: 55,
          fontSize: '22px',
          fontWeight: 600,
          lineHeight: 1.39,
          textAlign: 'center',
          color: '#ededed',
          borderRadius: '10',
          display: 'flex',
          backgroundColor: '#b42b2d',
          border: 'none',
          '@media only screen and (max-width: 480px)': {
            margin: '30px auto 0 auto',
          },
          '&:hover': {
            backgroundColor: '#b42b2d',
          },
          a: {
            textDecoration: 'none',
            color: '#ededed',
          },
        })}
        onClick={() => {
          if (!isAuth) window?.kioskVEHome();
        }}
      >
        <Link to={isAuth ? `/?auth=${machineUUID}` : `/?machineUUID=${machineUUID}`} state={{ machineUUID }}>
          Back to home
        </Link>
      </Button>
    </Container>
  );
};

export default BookingSuccessful;
