import React from 'react';
import { Box, Image, Title } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';

import Logo from '../../assets/images/logo.png';
import arrowBack from '../../assets/images/arrow-icon.png';

const url = process.env.REACT_APP_APP_API_BACKEND_SERVER;
const PageTitle = ({ uuid, title, isBack = false, logo }) => {
  const navigate = useNavigate();
  return (
    <Box
      style={{
        margin: '0 auto 20px auto',
      }}
    >
      <Link to="/" state={{ uuid }}>
        <Image
          style={{
            width: '110px',
            // height: '110px',
            // backgroundColor: 'red',
            margin: '28px auto 10px auto',
          }}
          src={`${logo}`}
          alt=""
        />
      </Link>
      {title && (
        <Title
          style={{
            margin: 'auto',
            textAlign: 'center',
            fontSize: '40px',
            fontWeight: 500,
            color: '#ededed',
          }}
          order={6}
        >
          {title}
        </Title>
      )}

      {/* {isBack && <Image
      onClick={() => navigate(-1, state={ uuid }) || navigate('/', state={ uuid })}
      style={{
        width: '55px',
        height: '44px',
        margin: '28px  0 10px 0',
        cursor: 'pointer'
      }}
      src={arrowBack}
      alt=""
    />} */}
    </Box>
  );
};

export default PageTitle;
