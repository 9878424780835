import moment from 'moment';

export const getAmountFromPercent = (percent, expectedCost) => {
  if (!percent) return 0;
  const percentageAmount = (expectedCost * percent) / 100;
  return parseFloat(percentageAmount).toFixed(2);
};

export const getCurrencyText = (currency) => {
  if (currency === 1) return 'RM';
  return 'SGD';
};

export const getFeeFromBookingAmount = (fee, bookingAmount) => (Number(fee) / Number(bookingAmount)) * 100;

export const calculateEndTime = (startTime, hours) => {
  const endTime = moment(startTime).add('hours', hours);
  return endTime;
};

const pricelist = (price, day, currency) =>
  price?.map((item) => {
    const d = moment(item?.start_time, 'HH:mm');
    return `${day} : ${item?.start_time} - ${d.add(item?.no_of_hours, 'h').format('HH:00')}${`(${currency}`}${
      item?.default
    }/hr)`;
  });
const days = (item) => {
  const dayOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
    'Public Holiday',
  ];

  return dayOfWeek[item];
};

const Pricing = (timings, currency) =>
  timings?.map((item) => {
    const d =
      item?.days.length < 2
        ? days(item?.days[0])
        : `${days(item?.days[0])} to ${days(item?.days[item?.days?.length - 1])}`;
    return pricelist(item?.prices, d, currency);
  });

export const PriceListing = (timings, currency) => {
  const pricingLi = Pricing(timings, currency);
  const pricenow = pricingLi.flat();
  return [...new Set(pricenow)];
};
