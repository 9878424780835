import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Container, Box, Title, Text, Button, Table, Modal, Chips, Chip, Select, Image } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { Scrollbars } from 'react-custom-scrollbars';
import { Calendar, ChevronDown } from 'tabler-icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
// Components
import PageTitle from '../../components/PageTitle/PageTitle';
import Loader from '../../components/Loader/Loader';
import { PriceListing } from '../../helpers';
// API-Services
import { complexBookingService, checkAvailabilityService, checkAvailabilityService2 } from '../../service/API.service';

import ArrowBack from '../../assets/images/arrow-icon.png';

const Scheduler = () => {
  const [loading, setLoading] = useState(true);
  const [opened, setOpened] = useState(false);
  const [opendBookingModal, setOpendBookingModal] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');
  const [checkedValue, setCheckedValue] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [bookingStartTime, setBookingStartTime] = useState(null);
  const [bookingAmPm, setBookingAmPm] = useState('AM');
  const [duration, setDuration] = useState(1);
  const [listOfCourts, setListOfCourts] = useState([]);
  const [isCheckedButton, setIsCheckedButton] = useState(true);
  const [timeArray, setTimeArray] = useState([]);

  const [date, setDate] = useState(new Date());

  const [bookingDate, setBookingDate] = useState(new Date());

  let sortedTimings = [];
  let minMaxTime = [];

  const location = useLocation();
  const navigate = useNavigate();
  const {
    facilitiesTitle,
    courts,
    timeSlots,
    id,
    uuid,
    is30MinAllowed,
    timings,
    minBookingHours,
    currency,
    complexImage,
    maxBookingDays,
  } = location.state;
  const timingsSS = PriceListing(timings, currency);

  const tdclick = (event, item) => {
    setOpendBookingModal(true);
    const timeStringArray = item.split('-');
    let startTime = timeStringArray?.[0];
    const endTime = timeStringArray?.[1];
    const timeItem = { start: startTime };
    setSelectedTime(timeItem);

    if (!is30MinAllowed) {
      const split = startTime.split(':');
      const hours = split[0];
      startTime = `${hours}:00`;
      timeItem.start = startTime;
      setSelectedTime(timeItem);
    }

    // Prepend any date. Use your birthday.
    const timeString12hr = new Date(`1970-01-01T${startTime}Z`).toLocaleTimeString('en-US', {
      timeZone: 'UTC',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    });

    setBookingStartTime(timeString12hr);
    const [hours] = timeItem?.start.split(':');
    if (parseInt(hours, 10) > 12) {
      setBookingAmPm('PM');
    }
    event.stopPropagation();
  };

  const Timings = [];
  let minMaxT = '';
  if (typeof timings !== 'undefined') {
    for (let i = 0; i < timings.length; i += 1) {
      for (let j = 0; j < timings[i].prices.length; j += 1) {
        Timings.push({ ...timings[i].prices[j], days: timings[i].days });
      }
    }

    minMaxT = Timings.reduce((acc, val) => {
      acc[0] = acc[0] === undefined || val.start_time < acc[0].start_time ? val : acc[0];
      acc[1] = acc[1] === undefined || val.end_time > acc[1].end_time || val.end_time === '00:00' ? val : acc[1];
      return acc;
    }, []);
    sortedTimings = JSON.parse(JSON.stringify(Timings));
    minMaxTime = minMaxT;
  }

  const convert12to24 = () => {
    let [hours, minutes] = bookingStartTime.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (bookingAmPm === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  };

  const calculateEndTime = () => {
    let [hours, minutes] = bookingStartTime.split(':');
    let extraMins = 0;
    if (!Number.isInteger(Number(duration))) {
      extraMins = 30;
    }
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);
    minutes += extraMins;
    if (minutes === 60) {
      hours += 1;
      minutes = '00';
      hours += parseInt(duration, 10);
    } else {
      hours += parseInt(duration, 10);
      if (minutes === 0) {
        minutes = '00';
      }
    }
    return `${hours}:${minutes}${bookingAmPm}`;
  };

  const fetchBooking = async () => {
    const result = await complexBookingService({ id, uuid, date: moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD') });
    if (result?.data?.success === 1) {
      setBookingData(result?.data?.data);
      const timingArray = Object.keys(result?.data?.data);
      setTimeArray(timingArray);
      setLoading(false);
    } else {
      setLoading(false);
      Swal.fire({
        title: 'Error!',
        text: result?.data?.errorMessage || 'Something went wrong',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#b42b2d',
      });
    }
  };

  // Check availability button
  const handleCheckAvailability = async () => {
    if (!bookingDate || !duration || !bookingStartTime) return;
    console.log('handleCheckAvailability');
    const result = await checkAvailabilityService({
      sports_facility_id: id,
      date: moment(bookingDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      start: convert12to24(),
      no_of_hours: Number(duration),
      uuid,
    });
    console.log(result);

    if (result?.data?.success === 1) {
      setListOfCourts(result?.data?.data);
      setIsCheckedButton(false);
    } else {
      Swal.fire({
        title: 'Error!',
        text: result?.data?.message || 'Something went wrong',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#b42b2d',
      });
    }
  };
  // Proceed with booking for Make a Booking Flow
  const proceedWithBooking = async () => {
    if (!checkedValue || !checkedValue.length) return;
    const result = await checkAvailabilityService2({
      sports_facility_id: id,
      date: moment(bookingDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      start: convert12to24(),
      no_of_hours: Number(duration),
      court_nos: checkedValue,
      uuid,
    });
    if (result?.data?.success === 1) {
      console.log(result);
      navigate('/booking', {
        state: {
          sports_facility_id: id,
          court_nos: checkedValue,
          date: moment(bookingDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          start: convert12to24(),
          no_of_hours: Number(duration),
          total_expected_cost: result?.data?.data?.final_amount,
          sports_name: result?.data?.data?.sports_facility?.name,
          sports_thumb: result?.data?.data?.sports_facility?.images || [],
          sports_end_time: calculateEndTime(),
          sports_start_time: `${bookingStartTime}`,
          sports_start_bookingAmPm: bookingAmPm,
          uuid,
          facilitiesTitle,
          courts,
          timeSlots,
          id,
          isLockedBookingValue: false,
          is30MinAllowed,
          booking: result?.data?.data,
          complex: result?.data?.data?.sports_facility.sports_complex,
        },
      });
    } else {
      Swal.fire({
        title: 'Error!',
        text: result?.data?.message || 'Something went wrong',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#b42b2d',
      });
    }
  };

  // Check availabuility for schedular selected slot
  const handleCheckAvailabilityForPreSelected = async () => {
    if (!bookingDate || !duration || !selectedTime) return;
    console.log('handleCheckAvailabilityForPreSelected');
    const result = await checkAvailabilityService({
      sports_facility_id: id,
      date: moment(bookingDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      start: selectedTime?.start,
      no_of_hours: Number(duration),
      uuid,
    });
    console.log(result);
    if (result?.data?.success === 1) {
      setListOfCourts(result?.data?.data);
      setIsCheckedButton(false);
    } else {
      Swal.fire({
        title: 'Error!',
        text: result?.data?.message || 'Something went wrong',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#b42b2d',
      });
    }
  };

  // Proceed with booking for schedular selected slot

  const proceedWithBookingForPreSelectedSlot = async () => {
    if (!checkedValue || !checkedValue.length) return;
    const result = await checkAvailabilityService2({
      sports_facility_id: id,
      date: moment(bookingDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      start: selectedTime?.start,
      no_of_hours: Number(duration),
      court_nos: checkedValue,
      uuid,
    });
    if (result?.data?.success === 1) {
      console.log(result);
      navigate('/booking', {
        state: {
          sports_facility_id: id,
          court_nos: checkedValue,
          date: moment(bookingDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          start: selectedTime?.start,
          no_of_hours: Number(duration),
          total_expected_cost: result?.data?.data?.final_amount,
          sports_name: result?.data?.data?.sports_facility?.name,
          sports_thumb: result?.data?.data?.sports_facility?.images || [],
          sports_end_time: calculateEndTime(),
          sports_start_time: `${bookingStartTime}`,
          sports_start_bookingAmPm: bookingAmPm,
          uuid,
          facilitiesTitle,
          courts,
          timeSlots,
          id,
          isLockedBookingValue: false,
          is30MinAllowed,
          booking: result?.data?.data,
          complex: result?.data?.data?.sports_facility?.sports_complex,
        },
      });
    } else {
      Swal.fire({
        title: 'Error!',
        text: result?.data?.message || 'Something went wrong',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#b42b2d',
      });
    }
  };

  const futureLimit = new Date();
  futureLimit.setDate(futureLimit.getDate() + maxBookingDays);

  const getDays = (days) => {
    const daysArray = [];
    days
      .sort((a, b) => a - b)
      .map((d) => {
        if (d === 1) {
          daysArray.push('Mon');
        } else if (d === 2) {
          daysArray.push('Tue');
        } else if (d === 3) {
          daysArray.push('Wed');
        } else if (d === 4) {
          daysArray.push('Thu');
        } else if (d === 5) {
          daysArray.push('Fri');
        } else if (d === 6) {
          daysArray.push('Sat');
        } else if (d === 7) {
          daysArray.push('Sun');
        } else if (d === 0) {
          daysArray.push('Daily');
        } else if (d === 8) {
          daysArray.push('Public Holiday');
        }
        return daysArray;
      });
    return daysArray;
  };

  useEffect(() => {
    fetchBooking();
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, [date]);

  const handleIsCourtBooked = (startTime, endTime) => {
    let result = {
      isRowEffected: false,
      effectedCourts: null,
    };
    bookingData.map((responsItem) => {
      if (responsItem?.start === startTime && responsItem?.end === endTime) {
        result = {
          isRowEffected: true,
          effectedCourts: responsItem?.courts,
        };
        return result;
      }
      return false;
    });
    return result;
  };
  return (
    <Container size="md" px="md">
      <PageTitle uuid={uuid} logo={complexImage} />
      <Image
        onClick={() => navigate('/', { state: { uuid } })}
        sx={() => ({
          width: '55px',
          height: '44px',
          margin: '60px 0 10px 0',
          cursor: 'pointer',
          '@media (max-width: 480px)': {
            margin: '30px auto',
          },
        })}
        src={ArrowBack}
        alt=""
      />
      {!uuid && (
        <Title
          style={{
            margin: 'auto',
            textAlign: 'center',
            fontSize: '40px',
            fontWeight: 500,
            color: '#ededed',
          }}
          map
          order={6}
        >
          Empty/Invalid UUID
        </Title>
      )}
      {uuid && loading ? (
        <Loader />
      ) : (
        <>
          <Box
            sx={() => ({
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '-90px',
              '@media (max-width: 480px)': {
                marginTop: '20px',
                justifyContent: 'center',
              },
            })}
          >
            <Button
              onClick={() => setOpened(true)}
              sx={() => ({
                width: '250px',
                height: '80px',
                padding: '10px',
                borderRadius: '10px',
                backgroundColor: '#b42b2d',
                fontSize: '28px',
                fontWeight: 500,
                lineHeight: 1.04,
                letterSpacing: '0.25px',
                marginBottom: '30px',
                zIndex: '9',
                boxShadow: '-1px 1px 19px -4px rgba(0,0,0,0.75)',
                '&:hover': {
                  backgroundColor: '#b42b2d',
                },
                '@media (max-width: 480px)': {
                  width: '200px',
                  height: '60px',
                  fontSize: '22px',
                },
              })}
            >
              Make Booking
            </Button>
          </Box>
          <Box
            sx={() => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '15px',
              borderTop: '1px solid',
              borderLeft: '1px solid',
              borderRight: '1px solid',
              borderColor: '#43444A',
              background: '#252a3d',
              '@media (max-width: 480px)': {
                flexDirection: 'column',
                gap: 15,
              },
            })}
          >
            <Title
              order={6}
              sx={() => ({
                fontSize: 30,
                fontWeight: 500,
                lineHeight: 0.91,
                '@media (max-width: 480px)': {
                  fontSize: 26,
                },
              })}
            >
              {facilitiesTitle}
            </Title>
            <DatePicker
              placeholder="Pick date"
              minDate={new Date()}
              maxDate={futureLimit}
              value={date}
              onChange={(e) => {
                setDate(e);
                setBookingDate(e);
              }}
              defaultValue={new Date()}
              icon={<Calendar size={16} />}
            />
          </Box>
          <Scrollbars
            style={{
              height: '700px',
              position: 'relative',
            }}
          >
            <Table
              sx={() => ({
                borderCollapse: 'collapse',
                width: '100%',
                '&.mantine-Table-root tr td:first-of-type': {
                  position: 'sticky',
                  width: '120px',
                  left: 0,
                  zIndex: 2,
                },
                '&.mantine-Table-root th:first-of-type': {
                  position: 'sticky',
                  width: '120px',
                  left: 0,
                  zIndex: 2,
                },
                '@media (max-width: 480px)': {
                  '&.mantine-Table-root tr td:first-of-type': {
                    width: '50px !important',
                    padding: '7px !important',
                    textAlign: 'center',
                  },
                  '&.mantine-Table-root th:first-of-type': {
                    width: '50px !important',
                    padding: '7px !important',
                    textAlign: 'center',
                  },
                  '&.mantine-Table-root tr th': {
                    minWidth: '50px !important',
                    padding: '7px !important',
                    textAlign: 'center',
                  },
                  '&.mantine-Table-root tr td': {
                    minWidth: '50px !important',
                    padding: '7px !important',
                    textAlign: 'center',
                  },
                },
              })}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      position: 'sticky',
                      top: 0,
                      padding: '15px',
                      border: '2px solid #43444A',
                      background: '#9DE0AD',
                      color: '#1A282F',
                      zIndex: 100,
                    }}
                  >
                    Court No.{' '}
                  </th>
                  {courts.map((courtsItem) => (
                    <th
                      key={courtsItem}
                      style={{
                        position: 'sticky',
                        top: 0,
                        padding: '15px',
                        border: '2px solid #43444A',
                        background: '#9DE0AD',
                        color: '#1A282F',
                        minWidth: '100px',
                        textAlign: 'center',
                        zIndex: '99',
                      }}
                    >
                      {courtsItem}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {timeArray.map((item) => (
                  <tr key={item}>
                    <td
                      style={{
                        padding: '15px',
                        border: '2px solid #43444A',
                        background: '#9DE0AD',
                        color: '#1A282F',
                        minWidth: '130px',
                      }}
                    >
                      {`${item}`}
                    </td>

                    {courts.map((columnItem) => (
                      <td
                        onClick={(event) => {
                          if (bookingData[item][columnItem] === true) tdclick(event, item);
                        }}
                        onKeyPress={() => true}
                        key={columnItem}
                        role="presentation"
                        style={{
                          padding: '15px',
                          border: '2px solid #43444A',
                          textAlign: 'center',
                          minWidth: '100px',
                          background: bookingData[item][columnItem] === true ? '#1A282F' : '#b42b2d',
                        }}
                      />
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Scrollbars>
        </>
      )}

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Make Booking"
        centered
        sx={() => ({
          '.mantine-Modal-title': {
            fontSize: 30,
          },
          '.mantine-oy9g3x': {
            minWidth: '700px',
            backgroundColor: '#252a3d',
          },
          '@media (max-width: 667px)': {
            '.mantine-oy9g3x': {
              minWidth: 'auto',
            },
          },
          '@media (max-width: 480px)': {
            '.mantine-Modal-title': {
              fontSize: 26,
            },
          },
        })}
      >
        {/* Modal content */}
        <Box
          style={{
            marginTop: 45,
          }}
        >
          <DatePicker
            placeholder="Pick date"
            minDate={new Date()}
            maxDate={futureLimit}
            value={bookingDate}
            onChange={(e) => {
              setBookingDate(e);
              setIsCheckedButton(true);
            }}
            defaultValue={bookingDate}
            label="Date"
            icon={<Calendar size={16} />}
            sx={() => ({
              label: {
                fontSize: 26,
                '@media (max-width: 480px)': {
                  fontSize: 18,
                },
              },
            })}
          />
          <Title
            order={5}
            sx={() => ({
              fontSize: 30,
              fontWeight: 400,
              marginTop: 50,
              marginBottom: 20,
              '@media (max-width: 480px)': {
                fontSize: 22,
              },
            })}
          >
            Select Number of Hours
          </Title>
          <Box
            sx={() => ({
              display: 'flex',
              alignItems: 'center',
              '@media (max-width: 480px)': {
                flexDirection: 'column',
                gap: 20,
              },
            })}
          >
            <Box
              sx={() => ({
                display: 'flex',
                alignItems: 'end',
                flex: '1 1 50%',
                maxWidth: '70%',
                gap: '0 15px',
                '@media (max-width: 480px)': {
                  alignItems: 'center',
                  flex: '1 1 100%',
                  maxWidth: '100%',
                  gap: 20,
                  flexDirection: 'column',
                },
              })}
            >
              <Box
                sx={() => ({
                  flex: '1 1 50%',
                  maxWidth: '50%',
                  '@media (max-width: 480px)': {
                    flex: '1 1 100%',
                    maxWidth: '100%',
                  },
                })}
              >
                <Text
                  sx={() => ({
                    fontSize: 23,
                    '@media (max-width: 480px)': {
                      fontSize: 18,
                    },
                  })}
                >
                  Start Time
                </Text>
                <Select
                  placeholder="Select Start Time"
                  rightSection={<ChevronDown size={22} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  onChange={(value) => {
                    setBookingStartTime(value);
                    setIsCheckedButton(true);
                  }}
                  data={
                    is30MinAllowed
                      ? [
                          { value: '01:00', label: '1:00' },
                          { value: '01:30', label: '1:30' },
                          { value: '02:00', label: '2:00' },
                          { value: '02:30', label: '2:30' },
                          { value: '03:00', label: '3:00' },
                          { value: '03:30', label: '3:30' },
                          { value: '04:00', label: '4:00' },
                          { value: '04:30', label: '4:30' },
                          { value: '05:00', label: '5:00' },
                          { value: '05:30', label: '5:30' },
                          { value: '06:00', label: '6:00' },
                          { value: '06:30', label: '6:30' },
                          { value: '07:00', label: '7:00' },
                          { value: '07:30', label: '7:30' },
                          { value: '08:00', label: '8:00' },
                          { value: '08:30', label: '8:30' },
                          { value: '09:00', label: '9:00' },
                          { value: '09:30', label: '9:30' },
                          { value: '10:00', label: '10:00' },
                          { value: '10:30', label: '10:30' },
                          { value: '11:00', label: '11:00' },
                          { value: '11:30', label: '11:30' },
                          { value: '12:00', label: '12:00' },
                        ]
                      : [
                          { value: '01:00', label: '1:00' },
                          { value: '02:00', label: '2:00' },
                          { value: '03:00', label: '3:00' },
                          { value: '04:00', label: '4:00' },
                          { value: '05:00', label: '5:00' },
                          { value: '06:00', label: '6:00' },
                          { value: '07:00', label: '7:00' },
                          { value: '08:00', label: '8:00' },
                          { value: '09:00', label: '9:00' },
                          { value: '10:00', label: '10:00' },
                          { value: '11:00', label: '11:00' },
                          { value: '12:00', label: '12:00' },
                        ]
                  }
                />
              </Box>
              <Select
                sx={() => ({
                  flex: '1 1 30%',
                  maxWidth: '30%',
                  select: {
                    fontSize: 16,
                  },
                  '@media (max-width: 480px)': {
                    flex: '1 1 100%',
                    maxWidth: '100%',
                  },
                })}
                placeholder="Select AM/PM"
                rightSection={<ChevronDown size={22} />}
                rightSectionWidth={30}
                styles={{ rightSection: { pointerEvents: 'none' } }}
                onChange={(value) => {
                  setBookingAmPm(value);
                }}
                data={[
                  { value: 'AM', label: 'AM' },
                  { value: 'PM', label: 'PM' },
                ]}
              />
            </Box>
            <Box>
              <Text
                sx={() => ({
                  fontSize: 23,
                  '@media (max-width: 480px)': {
                    fontSize: 18,
                  },
                })}
              >
                Duration
              </Text>
              <Select
                sx={() => ({
                  select: {
                    fontSize: 16,
                  },
                })}
                placeholder="Select Duration"
                rightSection={<ChevronDown size={22} />}
                rightSectionWidth={30}
                styles={{ rightSection: { pointerEvents: 'none' } }}
                onChange={(value) => {
                  setDuration(value);
                }}
                data={
                  is30MinAllowed
                    ? [
                        { value: '0.5', label: '30 Mins' },
                        { value: '1', label: '1 Hour' },
                        { value: '1.5', label: '1.5 Hours' },
                        { value: '2', label: '2 Hours' },
                        { value: '2.5', label: '2.5 Hours' },
                        { value: '3', label: '3 Hours' },
                        { value: '3.5', label: '3.5 Hours' },
                        { value: '4', label: '4 Hours' },
                        { value: '4.5', label: '4.5 Hours' },
                        { value: '5', label: '5 Hours' },
                        { value: '5.5', label: '5.5 Hours' },
                        { value: '6', label: '6 Hours' },
                      ]
                    : [
                        { value: '1', label: '1 Hour' },
                        { value: '2', label: '2 Hours' },
                        { value: '3', label: '3 Hours' },
                        { value: '4', label: '4 Hours' },
                        { value: '5', label: '5 Hours' },
                        { value: '6', label: '6 Hours' },
                      ]
                }
              />
            </Box>
          </Box>
          <Text
            sx={() => ({
              fontStyle: 'italic',
              lineHeight: 1.4,
              letterSpacing: '1px',
              color: '#dcdcdc',
              marginTop: 30,
              fontSize: 23,
              '@media (max-width: 480px)': {
                fontSize: 18,
                br: {
                  display: 'none',
                },
              },
            })}
          >
            Please double check your booking details. <br />
            No refund or cancellation allowed after booking is made.
          </Text>
          <Box
            sx={() => ({
              padding: '30px',
              borderRadius: '15px',
              backgroundColor: '#1c2030',
              marginTop: 50,
              '@media (max-width: 480px)': {
                padding: '15px',
              },
            })}
          >
            <Title
              order={5}
              style={{
                fontSize: 25,
                fontWeight: 400,
                marginTop: 30,
                marginBottom: 20,
              }}
            >
              Available courts
            </Title>
            <Box>
              {!isCheckedButton && (
                <Chips value={checkedValue} onChange={setCheckedValue} multiple>
                  {listOfCourts.map((courtsItem) => {
                    if (courtsItem?.available) {
                      return (
                        <Chip
                          key={courtsItem?.court}
                          value={courtsItem?.court}
                          sx={() => ({
                            label: {
                              padding: '10px 15px',
                              background: '#ffffff',
                              borderRadius: '5px',
                              margin: '5px',
                              minWidth: '115px',
                              cursor: 'pointer',
                              height: 40,
                              display: 'inline-flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: '#515151',
                              fontSize: 17,
                              fontWeight: 500,
                              '@media (max-width: 480px)': {
                                minWidth: '90px',
                              },
                            },
                            '.mantine-foc7wg:hover': {
                              background: 'rgb(180, 43, 45)',
                              color: '#ffffff',
                            },
                            '.mantine-jypj8n.__mantine-ref-filled': {
                              background: 'rgb(180, 43, 45)',
                              color: '#ffffff',
                            },
                            '.mantine-jypj8n.__mantine-ref-filled:hover': {
                              background: 'rgb(180, 43, 45)',
                              color: '#ffffff',
                            },
                            span: {
                              color: '#ffffff',
                            },
                          })}
                        >
                          {courtsItem?.court}
                        </Chip>
                      );
                    }
                    return (
                      <Chip
                        key={courtsItem?.name}
                        value={courtsItem?.name}
                        sx={() => ({
                          label: {
                            padding: '10px 15px',
                            background: '#ffffff',
                            borderRadius: '5px',
                            margin: '5px',
                            minWidth: '115px',
                            cursor: 'pointer',
                            height: 40,
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#515151',
                            fontSize: 17,
                            fontWeight: 500,
                            '@media (max-width: 480px)': {
                              minWidth: '90px',
                            },
                          },
                          '.mantine-foc7wg:hover': {
                            background: 'rgb(180, 43, 45)',
                            color: '#ffffff',
                          },
                          '.mantine-jypj8n.__mantine-ref-filled': {
                            background: 'rgb(180, 43, 45)',
                            color: '#ffffff',
                          },
                          '.mantine-jypj8n.__mantine-ref-filled:hover': {
                            background: 'rgb(180, 43, 45)',
                            color: '#ffffff',
                          },
                          span: {
                            color: '#ffffff',
                          },
                        })}
                        disabled
                      >
                        {courtsItem?.name}
                      </Chip>
                    );
                  })}
                </Chips>
              )}

              {isCheckedButton && (
                <Box
                  style={{
                    textAlign: 'center',
                    margin: '30px 0',
                  }}
                >
                  <Button
                    sx={() => ({
                      width: '280px',
                      height: '50px',
                      padding: '0 30px',
                      borderRadius: '10px',
                      backgroundColor: '#b42b2d',
                      fontSize: '20px',
                      fontWeight: 500,
                      lineHeight: 1.04,
                      letterSpacing: '0.25px',
                      margin: '0 auto',
                      '@media (max-width: 480px)': {
                        width: '192px',
                        fontSize: '16px',
                        padding: '0 8px',
                      },
                    })}
                    onClick={handleCheckAvailability}
                  >
                    Check Available Courts
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            <Text
              sx={() => ({
                fontStyle: 'italic',
                lineHeight: 1.4,
                letterSpacing: '1px',
                color: '#dcdcdc',
                marginTop: 30,
                fontSize: 23,
                '@media (max-width: 480px)': {
                  fontSize: 18,
                  br: {
                    display: 'none',
                  },
                },
              })}
            >
              Requirements of booking
            </Text>
            <Text
              sx={() => ({
                fontStyle: 'italic',
                lineHeight: 1.4,
                letterSpacing: '1px',
                color: '#dcdcdc',
                marginTop: 30,
                fontSize: 18,
                '@media (max-width: 480px)': {
                  fontSize: 18,
                  br: {
                    display: 'none',
                  },
                },
              })}
            >
              Minimum Booking Hour
            </Text>
            {timingsSS.map((t) => (
              <Text
                sx={() => ({
                  fontStyle: 'italic',
                  lineHeight: 1.4,
                  letterSpacing: '1px',
                  color: '#dcdcdc',
                  marginTop: 30,
                  fontSize: 18,
                  '@media (max-width: 480px)': {
                    fontSize: 18,
                    br: {
                      display: 'none',
                    },
                  },
                })}
              >
                {t}
              </Text>
            ))}
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: 50,
              marginBottom: 60,
            }}
          >
            <Button
              sx={() => ({
                width: '280px',
                height: '50px',
                padding: '0 30px',
                borderRadius: '10px',
                backgroundColor: '#b42b2d',
                fontSize: '20px',
                fontWeight: 500,
                lineHeight: 1.04,
                letterSpacing: '0.25px',
                '@media (max-width: 480px)': {
                  width: '192px',
                  fontSize: '16px',
                  padding: '0 8px',
                },
              })}
              onClick={proceedWithBooking}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        opened={opendBookingModal}
        onClose={() => {
          setOpendBookingModal(false);
          setIsCheckedButton(true);
        }}
        title="Make Booking"
        centered
        sx={() => ({
          '.mantine-Modal-title': {
            fontSize: 30,
          },
          '.mantine-oy9g3x': {
            minWidth: '700px',
            backgroundColor: '#252a3d',
          },
          '@media (max-width: 667px)': {
            '.mantine-oy9g3x': {
              minWidth: 'auto',
            },
          },
          '@media (max-width: 480px)': {
            '.mantine-Modal-title': {
              fontSize: 26,
            },
          },
        })}
      >
        {/* Modal content */}
        <Box
          style={{
            marginTop: 45,
          }}
        >
          <DatePicker
            placeholder="Pick date"
            minDate={new Date()}
            maxDate={futureLimit}
            value={bookingDate}
            onChange={(e) => {
              setBookingDate(e);
              setIsCheckedButton(true);
            }}
            defaultValue={bookingDate}
            label="Date"
            icon={<Calendar size={16} />}
            sx={() => ({
              label: {
                fontSize: 26,
                '@media (max-width: 480px)': {
                  fontSize: 18,
                },
              },
            })}
          />
          <Title
            order={5}
            sx={() => ({
              fontSize: 30,
              fontWeight: 400,
              marginTop: 50,
              marginBottom: 20,
              '@media (max-width: 480px)': {
                fontSize: 22,
              },
            })}
          >
            Select Number of Hours
          </Title>
          <Box
            sx={() => ({
              display: 'flex',
              alignItems: 'center',
              '@media (max-width: 480px)': {
                flexDirection: 'column',
                gap: 20,
              },
            })}
          >
            <Box
              sx={() => ({
                display: 'flex',
                alignItems: 'center',
                flex: '1 1 50%',
                maxWidth: '50%',
                gap: '0 15px',
                '@media (max-width: 480px)': {
                  alignItems: 'center',
                  flex: '1 1 100%',
                  maxWidth: '100%',
                  gap: 20,
                  flexDirection: 'column',
                },
              })}
            >
              <Box
                sx={() => ({
                  flex: '1 1 60%',
                  maxWidth: '50%',
                  '@media (max-width: 480px)': {
                    flex: '1 1 100%',
                    maxWidth: '100%',
                  },
                })}
              >
                <Text
                  sx={() => ({
                    fontSize: 23,
                    '@media (max-width: 480px)': {
                      fontSize: 18,
                    },
                  })}
                >
                  Start Time
                </Text>
                <Text
                  sx={() => ({
                    fontSize: 23,
                    '@media (max-width: 480px)': {
                      fontSize: 18,
                    },
                  })}
                >
                  {bookingStartTime}
                </Text>
              </Box>
            </Box>
            <Box style={{ width: '60%' }}>
              <Text
                sx={() => ({
                  fontSize: 23,
                  '@media (max-width: 480px)': {
                    fontSize: 18,
                  },
                })}
              >
                Duration
              </Text>
              <Select
                sx={() => ({
                  select: {
                    fontSize: 16,
                  },
                })}
                placeholder="Select Duration"
                rightSection={<ChevronDown size={22} />}
                rightSectionWidth={30}
                styles={{ rightSection: { pointerEvents: 'none' } }}
                onChange={(value) => {
                  setDuration(value);
                }}
                data={
                  is30MinAllowed
                    ? [
                        { value: '0.5', label: '30 Mins' },
                        { value: '1', label: '1 Hour' },
                        { value: '1.5', label: '1.5 Hours' },
                        { value: '2', label: '2 Hours' },
                        { value: '2.5', label: '2.5 Hours' },
                        { value: '3', label: '3 Hours' },
                        { value: '3.5', label: '3.5 Hours' },
                        { value: '4', label: '4 Hours' },
                        { value: '4.5', label: '4.5 Hours' },
                        { value: '5', label: '5 Hours' },
                        { value: '5.5', label: '5.5 Hours' },
                        { value: '6', label: '6 Hours' },
                      ]
                    : [
                        { value: '1', label: '1 Hour' },
                        { value: '2', label: '2 Hours' },
                        { value: '3', label: '3 Hours' },
                        { value: '4', label: '4 Hours' },
                        { value: '5', label: '5 Hours' },
                        { value: '6', label: '6 Hours' },
                      ]
                }
              />
            </Box>
          </Box>
          <Text
            sx={() => ({
              fontStyle: 'italic',
              lineHeight: 1.4,
              letterSpacing: '1px',
              color: '#dcdcdc',
              marginTop: 30,
              fontSize: 23,
              '@media (max-width: 480px)': {
                fontSize: 18,
                br: {
                  display: 'none',
                },
              },
            })}
          >
            Please double check your booking details. <br />
            No refund or cancellation allowed after booking is made.
          </Text>
          <Box
            sx={() => ({
              padding: '30px',
              borderRadius: '15px',
              backgroundColor: '#1c2030',
              marginTop: 50,
              '@media (max-width: 480px)': {
                padding: '15px',
              },
            })}
          >
            <Title
              order={5}
              style={{
                fontSize: 25,
                fontWeight: 400,
                marginTop: 30,
                marginBottom: 20,
              }}
            >
              Available courts
            </Title>
            <Box>
              {!isCheckedButton && (
                <Chips value={checkedValue} onChange={setCheckedValue} multiple>
                  {listOfCourts.map((courtsItem) => {
                    if (courtsItem?.available) {
                      return (
                        <Chip
                          key={courtsItem?.court}
                          value={courtsItem?.court}
                          sx={() => ({
                            label: {
                              padding: '10px 15px',
                              background: '#ffffff',
                              borderRadius: '5px',
                              margin: '5px',
                              minWidth: '115px',
                              cursor: 'pointer',
                              height: 40,
                              display: 'inline-flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: '#515151',
                              fontSize: 17,
                              fontWeight: 500,
                              '@media (max-width: 480px)': {
                                minWidth: '90px',
                              },
                            },
                            '.mantine-foc7wg:hover': {
                              background: 'rgb(180, 43, 45)',
                              color: '#ffffff',
                            },
                            '.mantine-jypj8n.__mantine-ref-filled': {
                              background: 'rgb(180, 43, 45)',
                              color: '#ffffff',
                            },
                            '.mantine-jypj8n.__mantine-ref-filled:hover': {
                              background: 'rgb(180, 43, 45)',
                              color: '#ffffff',
                            },
                            span: {
                              color: '#ffffff',
                            },
                          })}
                        >
                          {courtsItem?.court}
                        </Chip>
                      );
                    }
                    return (
                      <Chip
                        key={courtsItem?.court}
                        value={courtsItem?.court}
                        sx={() => ({
                          label: {
                            padding: '10px 15px',
                            background: '#ffffff',
                            borderRadius: '5px',
                            margin: '5px',
                            minWidth: '115px',
                            cursor: 'pointer',
                            height: 40,
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#515151',
                            fontSize: 17,
                            fontWeight: 500,
                            '@media (max-width: 480px)': {
                              minWidth: '90px',
                            },
                          },
                          '.mantine-foc7wg:hover': {
                            background: 'rgb(180, 43, 45)',
                            color: '#ffffff',
                          },
                          '.mantine-jypj8n.__mantine-ref-filled': {
                            background: 'rgb(180, 43, 45)',
                            color: '#ffffff',
                          },
                          '.mantine-jypj8n.__mantine-ref-filled:hover': {
                            background: 'rgb(180, 43, 45)',
                            color: '#ffffff',
                          },
                          span: {
                            color: '#ffffff',
                          },
                        })}
                        disabled
                      >
                        {courtsItem?.court}
                      </Chip>
                    );
                  })}
                </Chips>
              )}

              {isCheckedButton && (
                <Box
                  style={{
                    textAlign: 'center',
                    margin: '30px 0',
                  }}
                >
                  <Button
                    sx={() => ({
                      width: '280px',
                      height: '50px',
                      padding: '0 30px',
                      borderRadius: '10px',
                      backgroundColor: '#b42b2d',
                      fontSize: '20px',
                      fontWeight: 500,
                      lineHeight: 1.04,
                      letterSpacing: '0.25px',
                      margin: '0 auto',
                      '@media (max-width: 480px)': {
                        width: '192px',
                        fontSize: '16px',
                        padding: '0 8px',
                      },
                    })}
                    onClick={handleCheckAvailabilityForPreSelected}
                  >
                    Check Available Courts
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            <Text
              sx={() => ({
                fontStyle: 'italic',
                lineHeight: 1.4,
                letterSpacing: '1px',
                color: '#dcdcdc',
                marginTop: 30,
                fontSize: 23,
                '@media (max-width: 480px)': {
                  fontSize: 18,
                  br: {
                    display: 'none',
                  },
                },
              })}
            >
              Requirements of booking
            </Text>
            <Text
              sx={() => ({
                fontStyle: 'italic',
                lineHeight: 1.4,
                letterSpacing: '1px',
                color: '#dcdcdc',
                marginTop: 30,
                fontSize: 18,
                '@media (max-width: 480px)': {
                  fontSize: 18,
                  br: {
                    display: 'none',
                  },
                },
              })}
            >
              Minimum Booking Hour
            </Text>
            {timingsSS.map((t) => (
              <Text
                sx={() => ({
                  fontStyle: 'italic',
                  lineHeight: 1.4,
                  letterSpacing: '1px',
                  color: '#dcdcdc',
                  marginTop: 30,
                  fontSize: 18,
                  '@media (max-width: 480px)': {
                    fontSize: 18,
                    br: {
                      display: 'none',
                    },
                  },
                })}
              >
                {t}
              </Text>
            ))}
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: 50,
              marginBottom: 60,
            }}
          >
            <Button
              sx={() => ({
                width: '280px',
                height: '50px',
                padding: '0 30px',
                borderRadius: '10px',
                backgroundColor: '#b42b2d',
                fontSize: '20px',
                fontWeight: 500,
                lineHeight: 1.04,
                letterSpacing: '0.25px',
                '@media (max-width: 480px)': {
                  width: '192px',
                  fontSize: '16px',
                  padding: '0 8px',
                },
              })}
              onClick={proceedWithBookingForPreSelectedSlot}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default Scheduler;
