import React, { useState, useEffect } from 'react';
import { Container, Group, Box, Image, Title, Card, Text } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

// Components
import PageTitle from '../../components/PageTitle/PageTitle';
import Loader from '../../components/Loader/Loader';

// API-Service
import { facilitiesListService } from '../../service/API.service';
import { getCurrencyText } from '../../helpers';
// Images
import PlaceHolder from '../../assets/images/placeholder.png';
import ArrowBack from '../../assets/images/arrow-icon.png';

const Facilities = () => {
  const [loading, setLoading] = useState(true);
  const [complexImage, setComplexImage] = useState('');
  const [facilities, setFacilities] = useState([]);
  const location = useLocation();
  const [currency, setCurrency] = useState('MYR');
  const locationSearch = useLocation().search;
  let uuid = '';
  // Determine the source of booking either from kiosk or QR panel
  const isKiosk = new URLSearchParams(locationSearch).get('machineUUID');
  const isQR = new URLSearchParams(locationSearch).get('auth');
  if (isKiosk) {
    uuid = isKiosk || location?.state?.uuid;
  } else if (isQR) {
    uuid = isQR || location?.state?.uuid;
  } else {
    uuid = location?.state?.uuid;
  }

  localStorage.setItem('machineUUID', uuid);
  if (isQR) {
    localStorage.setItem('isAuth', true);
  } else localStorage.setItem('isAuth', false);
  // const uuid = 'VE000031';
  console.log('Auth', localStorage.getItem('isAuth'));
  const fetchFacilities = async () => {
    const result = await facilitiesListService(uuid, isKiosk, isQR);
    if (result?.data?.success === 1) {
      setLoading(false);
      setFacilities(result?.data?.data?.sports_facilities);
      const imageArray = result?.data?.data?.sports_complex.images;
      setCurrency(getCurrencyText(result?.data?.data?.sports_complex?.info?.currency));
      setComplexImage(`${imageArray[0]}`);
    } else {
      setLoading(false);
      Swal.fire({
        title: 'Error!',
        text: result?.data?.errorMessage || 'Something went wrong',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#2aa76c',
      });
    }
  };

  useEffect(() => {
    fetchFacilities();
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);
  // window?.kioskVEHome()

  const isAuth = localStorage.getItem('isAuth' || false);

  return (
    <Container size="md" px="md">
      <PageTitle uuid={uuid} title="Facilities" logo={complexImage} />
      {!uuid && (
        <Title
          style={{
            margin: 'auto',
            textAlign: 'center',
            fontSize: '40px',
            fontWeight: 500,
            color: '#ededed',
          }}
          order={6}
        >
          Empty/Invalid UUID
        </Title>
      )}
      {!isAuth && (
        <Image
          onClick={() => window?.kioskVEHome()}
          sx={() => ({
            width: '55px',
            height: '44px',
            margin: '28px  0 10px 0',
            cursor: 'pointer',
            '@media (max-width: 480px)': {
              margin: '30px auto',
            },
          })}
          src={ArrowBack}
          alt=""
        />
      )}
      {uuid && loading ? (
        <Loader />
      ) : (
        <Group
          sx={(_) => ({
            margin: '20px auto 50px auto',
            '@media (maxWidth: 910px)': {
              justifyContent: 'center',
            },
          })}
        >
          {facilities?.map((item) => (
            <Box
              key={item?.id}
              sx={() => ({
                width: '32%',
                margin: '15px auto',
                '@media (max-width: 990px)': {
                  width: '48%',
                },
                '@media (max-width: 910px)': {
                  width: '80%',
                },
                '@media (max-width: 480px)': {
                  width: '100%',
                },
              })}
            >
              <Link
                style={{
                  textDecoration: 'none',
                }}
                to={`${item.status === 1 ? '/scheduler' : `?machineUUID=${uuid}`}`}
                state={{
                  facilitiesTitle: item?.name,
                  courts: item?.courts,
                  timeSlots: item?.timeSlots || [],
                  id: item?.id,
                  uuid,
                  is30MinAllowed: item?.booking_window === '30.0',
                  timings: item.timings,
                  minBookingHours: Number(item.min_booking_hour),
                  currency,
                  complexImage,
                  maxBookingDays: item.max_booking_days,
                }}
              >
                <Card
                  style={{ borderRadius: 10, background: '#252a3d', filter: `blur(${item.status === 1 ? 0 : 3}px)` }}
                  shadow="sm"
                  p="md"
                >
                  <Card.Section>
                    <Image src={item?.images[0] ? `${item?.images[0]}` : PlaceHolder} height="150px" alt="" />
                  </Card.Section>
                  <Group position="center" style={{ marginBottom: 5, marginTop: 15, textAlign: 'center' }}>
                    <Text
                      style={{
                        fontSize: '25px',
                        fontWeight: 500,
                        lineHeight: '1.3',
                        letterSpacing: '0.4px',
                      }}
                    >
                      {item?.name}
                    </Text>
                  </Group>
                </Card>
              </Link>
            </Box>
          ))}
        </Group>
      )}
    </Container>
  );
};

export default Facilities;
