import React, { useState, useRef } from 'react';
import { MantineProvider, ColorSchemeProvider } from '@mantine/core';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Pages
import { useIdleTimer } from 'react-idle-timer';
import Facilities from './pages/Facilities/Facilities';
import Scheduler from './pages/Scheduler/Scheduler';
import Booking from './pages/Booking/Booking';
import BookingSuccessful from './pages/BookingSuccessful/BookingSuccessful';
import BookingError from './pages/BookingError/BookingError';
import Payment from './pages/Payment/Payment';

const App = () => {
  const [colorScheme, setColorScheme] = useState('dark');
  const [timeout, setTimeout] = useState(5000);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const toggleColorScheme = (value) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  const onActive = () => {
    console.log('user did something');
  };

  // Hook
  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    crossTab: true,
    onActive,
    onIdle: () => {
      console.log('You are timed out', getTabId());
      window?.kioskVEHome();
    },
    timeout: 1000 * 130 * 1,
    events: ['mousedown', 'touchstart', 'touchmove', 'MSPointerDown', 'MSPointerMove', 'visibilitychange'],
  });

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        theme={{
          fontFamily: "'Poppins', sans-serif",
          headings: { fontFamily: "'Poppins', sans-serif" },
          colorScheme,
          breakpoints: {
            xs: 500,
            sm: 800,
            md: 1000,
            lg: 1275,
            xl: 1800,
          },
          colors: {
            // override dark colors here to change them for all components
            dark: [
              '#d5d7e0',
              '#acaebf',
              '#8c8fa3',
              '#666980',
              '#4d4f66',
              '#34354a',
              '#2b2c3d',
              '#1d1e30',
              '#0c0d21',
              '#01010a',
            ],
          },
        }}
        withGlobalStyles
        defaultProps={{
          Container: {
            sizes: {
              xs: 540,
              sm: 720,
              md: 1024,
              lg: 1140,
              xl: 1320,
            },
          },
        }}
      >
        {/* <IdleTimer crossTab timeout={1000 * 10 * 1} /> */}
        {/* 
        <IdleTimer
          ref={idleTimer}
          element={document}
          onActive={onAction}
          onIdle={onIdle}
          onAction={onAction}
          debounce={250}
          timeout={timeout}
        /> */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Facilities />} />
            <Route path="/scheduler" element={<Scheduler />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/success" element={<BookingSuccessful />} />
            <Route path="/error" element={<BookingError />} />
            <Route path="/payment" element={<Payment />} />
          </Routes>
        </BrowserRouter>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default App;
