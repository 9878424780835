import React from 'react';
import { Box, Button, Title, Container, Image } from '@mantine/core';
import { CircleX } from 'tabler-icons-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import PageTitle from '../../components/PageTitle/PageTitle';

const BookingError = () => {
  const machineUUID = localStorage.getItem('machineUUID');
  const isAuth = localStorage.getItem('isAuth');

  return (
    <Container size="md" px="md">
      <PageTitle uuid={machineUUID} />

      <Box
        sx={() => ({
          background: '#252a3d',
          padding: '60px 110px',
          borderRadius: '15px',
          '@media only screen and (max-width: 900px)': {
            padding: '60px',
          },
          '@media only screen and (max-width: 700px)': {
            padding: '30px',
          },
          '@media only screen and (max-width: 480px)': {
            padding: '15px',
          },
        })}
      >
        <Title
          order={6}
          sx={() => ({
            fontSize: 40,
            fontWeight: 500,
            lineHeight: 0.5,
            color: '#e23638',
            textAlign: 'center',
            '@media only screen and (max-width: 480px)': {
              fontSize: 30,
              lineHeight: '35px',
            },
          })}
        >
          Booking Failed
        </Title>
        <CircleX
          style={{
            height: 112,
            width: 122,
            fontWeight: 400,
            color: '#e23638',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '40px auto',
          }}
        />
        {/* <Text
          style={{
            marginTop: 30,
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 300,
            lineHeight: 1.5,
            color: '#c1c1c1',
          }}
        >
          {sports_error_msg}
          <br />
          <span style={{ color: '#e23638' }}> {phoneNumber} </span>
        </Text> */}
        {/* <Button
          variant="outline"
          sx={() => ({
            padding: '15px 85px',
            margin: '60px auto 0 auto',
            minHeight: 55,
            fontSize: '18',
            fontWeight: 500,
            lineHeight: 1.39,
            textAlign: 'center',
            color: '#ededed',
            borderRadius: '10',
            border: 'solid 1px #ededed',
            display: 'flex',
            '@media only screen and (max-width: 480px)': {
              margin: '30px auto 0 auto',
            },
          })}
        >
          <Link
            to="/booking"
            state={{
              sports_facility_id,
              court_nos,
              date,
              start,
              no_of_hours,
              total_expected_cost,
              sports_name,
              sports_thumb,
              sports_end_time,
              sports_start_time,
              sports_start_bookingAmPm,
              uuid,
              facilitiesTitle,
              courts,
              timeSlots,
              id,
              isLockedBookingValue,
            }}
            style={{
              fontSize: 18,
              fontWeight: 500,
              lineHeight: 1.39,
              color: '#ededed',
              textDecoration: 'none',
            }}
          >
            Try again
          </Link>
        </Button> */}
      </Box>
      <Button
        sx={() => ({
          padding: '15px 50px',
          margin: '60px auto 35px auto',
          minHeight: 55,
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: 1.39,
          textAlign: 'center',
          color: '#ededed',
          borderRadius: '10',
          display: 'flex',
          backgroundColor: '#b42b2d',
          border: 'none',
          '@media only screen and (max-width: 480px)': {
            margin: '30px auto 0 auto',
          },
          '&:hover': {
            backgroundColor: '#b42b2d',
          },
          a: {
            textDecoration: 'none',
            color: '#ededed',
          },
        })}
        onClick={() => {
          if (!isAuth) window?.kioskVEHome();
        }}
      >
        <Link to={isAuth ? `/?auth=${machineUUID}` : `/?machineUUID=${machineUUID}`} state={{ machineUUID }}>
          Back to home
        </Link>
      </Button>
    </Container>
  );
};

export default BookingError;
