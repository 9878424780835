import React, { useState, useEffect } from 'react';
import { Container, InputWrapper, Input, Box, Image, Text, Title, Button, Select } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import { ChevronDown } from 'tabler-icons-react';

// Components
import PageTitle from '../../components/PageTitle/PageTitle';
import Loader from '../../components/Loader/Loader';
import { getAmountFromPercent, getCurrencyText, getFeeFromBookingAmount } from '../../helpers';
// API-Service
import { bookingLockedService, bookingService } from '../../service/API.service';
// Images
import PlaceHolder from '../../assets/images/placeholder.png';
import ArrowBack from '../../assets/images/arrow-icon.png';

const Booking = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    sports_facility_id,
    court_nos,
    date,
    start,
    no_of_hours,
    total_expected_cost,
    sports_name,
    sports_thumb,
    sports_end_time,
    sports_start_time,
    sports_start_bookingAmPm,
    uuid,
    facilitiesTitle,
    courts,
    timeSlots,
    id,
    isLockedBookingValue,
    booking,
    is30MinAllowed,
    complex,
  } = location.state;

  const [loading, setLoading] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [notValidated, setNotValidated] = useState(false);
  const [isLockedBooking, setIsLockedBooking] = useState(isLockedBookingValue || false);
  const [username, setUserName] = useState('Kiosk');
  const [countryCode, setCountryCode] = useState('+60');
  const [emailNotValidated, setEmailNotValidated] = useState(false);
  const [usernameNotValidated, setUsernameNotValidated] = useState(false);

  const handleIsLocked = async () => {
    const result = await bookingLockedService({
      sports_facility_id,
      court_nos,
      date,
      start,
      no_of_hours,
      uuid,
    });
    if (result?.data?.success) {
      setLoading(false);
      setIsLockedBooking(true);
    } else {
      setLoading(false);
      Swal.fire({
        title: 'Error!',
        text: result?.message || 'Something went wrong',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#b42b2d',
      });
      navigate('/scheduler', {
        state: {
          uuid,
          facilitiesTitle,
          courts,
          timeSlots,
          id,
          is30MinAllowed,
        },
      });
    }
  };

  useEffect(() => {
    // if (!isLockedBooking) {
    //   handleIsLocked();
    // }
    setLoading(false);
    setIsLockedBooking(true);
    if (isLockedBooking) {
      setLoading(false);
    }
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, [isLockedBooking]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (phoneNumber === '' || phoneNumber?.length < 8) {
      setNotValidated(true);
    }

    if (email === '') {
      setEmailNotValidated(true);
    }
    if (username === '') {
      setUsernameNotValidated(true);
    }
    const firstChar = phoneNumber.substring(0, 1);
    let phone = phoneNumber;
    if (firstChar === '0') {
      phone = phoneNumber.substring(1);
    }

    if (isLockedBooking && phone !== '' && phone?.length >= 8 && email !== '') {
      const result = await bookingService({
        sports_facility_id,
        court_nos,
        date,
        start,
        no_of_hours,
        user_email: email,
        user_phone: countryCode + phone,
        user_name: username,
        uuid,
      });
      if (result?.data?.success === 1) {
        setLoading(false);
        // navigate('/success',{
        //   state:{
        //     court_nos,
        //     date,
        //     total_expected_cost,
        //     sports_start_time,
        //     sports_start_bookingAmPm,
        //     sports_end_time
        //   }
        // });

        /* Payment methods
        "Cash/Terminal/Ipay/"
        Removing platform fee for kiosk
        */
        const finalAmount = parseFloat(
          Number(result?.data?.data?.booking?.final_amount) +
            Number(result?.data?.data?.booking?.info?.merchant_gst) +
            Number(result?.data?.data?.booking?.info?.merchant_fees) +
            Number(0)
        ).toFixed(2);
        // eslint-disable-next-line no-console
        console.log('booking payment info=>', result);
        navigate('/payment', {
          state: {
            desc: `Kiosk-${result.data?.data?.booking?.id}-${sports_name}-${date}-${start}`,
            refNo: result?.data?.data?.booking?.id,
            price: finalAmount,
            paymentUrl: result?.data?.data?.booking?.payment_url,
            remark: 'AFA-KIOSK',
            paymentMethod: 'Ipay',
            sports_facility_id,
            court_nos,
            date,
            start,
            no_of_hours,
            total_expected_cost,
            sports_name,
            sports_thumb,
            sports_end_time,
            sports_start_time,
            sports_start_bookingAmPm,
            phoneNumber: countryCode + phone,
            uuid,
            facilitiesTitle,
            courts,
            timeSlots,
            id,
            isLockedBookingValue: isLockedBooking,
          },
        });
      } else {
        setLoading(false);
        navigate('/error', {
          state: {
            sports_error_msg: result?.data?.message,
            sports_facility_id,
            court_nos,
            date,
            start,
            no_of_hours,
            total_expected_cost,
            sports_name,
            sports_thumb,
            sports_end_time,
            sports_start_time,
            sports_start_bookingAmPm,
            phoneNumber: countryCode + phone,
            uuid,
            facilitiesTitle,
            courts,
            timeSlots,
            id,
            isLockedBookingValue: isLockedBooking,
          },
        });
      }
    }
  };

  const handlePhoneNumber = (event) => {
    setNotValidated(false);
    setPhoneNumber(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setNotValidated(false);
    setUserName(event.target.value);
  };

  const handleEmail = (event) => {
    setNotValidated(false);
    setEmail(event.target.value);
  };
  const imageArray = complex?.images;

  return (
    <Container size="md" px="md">
      <PageTitle uuid={uuid} title="Booking" logo={imageArray[0].url} />
      {!uuid && (
        <Title
          style={{
            margin: 'auto',
            textAlign: 'center',
            fontSize: '40px',
            fontWeight: 500,
            color: '#ededed',
          }}
          order={6}
        >
          Empty/Invalid UUID
        </Title>
      )}
      <Image
        onClick={() => navigate(-1, { state: { uuid } })}
        sx={() => ({
          width: '55px',
          height: '44px',
          margin: '28px  0 10px 0',
          cursor: 'pointer',
          '@media (max-width: 480px)': {
            margin: '30px auto',
          },
        })}
        src={ArrowBack}
        alt=""
      />
      {uuid && loading ? (
        <Loader />
      ) : (
        <Box
          sx={() => ({
            background: '#252a3d',
            padding: '60px 110px',
            borderRadius: '15px',
            '@media only screen and (max-width: 900px)': {
              padding: '60px',
            },
            '@media only screen and (max-width: 700px)': {
              padding: '30px',
            },
            '@media only screen and (max-width: 480px)': {
              padding: '15px',
            },
          })}
        >
          <Image src={sports_thumb[0] ? sports_thumb[0] : PlaceHolder} height="200px" alt="" />
          <Title
            style={{
              fontSize: 30,
              lineHeight: 0.67,
              marginTop: 30,
              textAlign: 'center',
            }}
            order={6}
          >
            {sports_name}
          </Title>
          <Box
            style={{
              margin: '30px 0',
            }}
          >
            <Box
              sx={() => ({
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0',
              })}
            >
              <Text
                sx={() => ({
                  fontSize: 30,
                  lineHeight: 1,
                  color: '#ededed',
                  '@media only screen and (max-width: 480px)': {
                    fontSize: 22,
                  },
                })}
              >
                Date
              </Text>
              <Text
                sx={() => ({
                  fontSize: 28,
                  lineHeight: 1,
                  color: '#c1c1c1',
                  '@media only screen and (max-width: 480px)': {
                    fontSize: 22,
                  },
                })}
              >
                {moment(date).format('Do MMM YYYY')}
              </Text>
            </Box>
            <Box
              sx={() => ({
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0',
                '@media only screen and (max-width: 480px)': {
                  fontSize: 22,
                },
              })}
            >
              <Text
                sx={() => ({
                  fontSize: 30,
                  lineHeight: 1,
                  color: '#ededed',
                  '@media only screen and (max-width: 480px)': {
                    fontSize: 22,
                  },
                })}
              >
                Start Time
              </Text>
              <Text
                sx={() => ({
                  fontSize: 28,
                  lineHeight: 1,
                  color: '#c1c1c1',
                  '@media only screen and (max-width: 480px)': {
                    fontSize: 22,
                  },
                })}
              >
                {sports_start_time}
                {sports_start_time.includes('AM') || sports_start_time.includes('PM') ? '' : sports_start_bookingAmPm}
              </Text>
            </Box>
            <Box
              sx={() => ({
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0',
                '@media only screen and (max-width: 480px)': {
                  fontSize: 22,
                },
              })}
            >
              <Text
                sx={() => ({
                  fontSize: 30,
                  lineHeight: 1,
                  color: '#ededed',
                  '@media only screen and (max-width: 480px)': {
                    fontSize: 22,
                  },
                })}
              >
                End Time
              </Text>
              <Text
                sx={() => ({
                  fontSize: 28,
                  lineHeight: 1,
                  color: '#c1c1c1',
                  '@media only screen and (max-width: 480px)': {
                    fontSize: 22,
                  },
                })}
              >
                {sports_end_time}
              </Text>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0 25px 0',
                paddingBottom: 25,
                borderBottom: '1px solid #434343',
              }}
            >
              <Text
                style={{
                  fontSize: 30,
                  lineHeight: 1,
                  color: '#ededed',
                }}
              >
                Courts
              </Text>
              <Text
                sx={() => ({
                  fontSize: 28,
                  lineHeight: 1,
                  color: '#c1c1c1',
                  '@media (max-width: 480px)': {
                    fontSize: 22,
                  },
                })}
              >
                {court_nos?.map((courtItem) => (
                  <span key={courtItem}> {courtItem}, </span>
                ))}
              </Text>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0',
              }}
            >
              <Text
                style={{}}
                sx={() => ({
                  fontSize: 30,
                  lineHeight: 1,
                  color: '#ededed',
                  '@media (max-width: 480px)': {
                    fontSize: 21,
                  },
                })}
              >
                Booking Cost
              </Text>
              <Text
                style={{
                  fontSize: 28,
                  lineHeight: 1,
                  color: '#b42b2d',
                }}
              >
                {`${getCurrencyText(complex?.info?.currency)}${booking?.final_amount}`}
              </Text>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0',
              }}
            >
              <Text
                style={{}}
                sx={() => ({
                  fontSize: 30,
                  lineHeight: 1,
                  color: '#ededed',
                  '@media (maxWidth: 480px)': {
                    fontSize: 22,
                  },
                })}
              >
                Platform Fee
              </Text>
              <Text
                style={{
                  fontSize: 28,
                  lineHeight: 1,
                  color: '#b42b2d',
                }}
              >
                {`${getCurrencyText(complex?.info?.currency)}${parseFloat(0)}`}
              </Text>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0',
              }}
            >
              <Text
                style={{}}
                sx={() => ({
                  fontSize: 30,
                  lineHeight: 1,
                  color: '#ededed',
                  '@media (maxWidth: 480px)': {
                    fontSize: 22,
                  },
                })}
              >
                {`Merchant Fee(${getFeeFromBookingAmount(booking?.info?.merchant_fees, booking?.booking_amount)}%)`}
              </Text>
              <Text
                style={{
                  fontSize: 28,
                  lineHeight: 1,
                  color: '#b42b2d',
                }}
              >
                {`${getCurrencyText(complex?.info?.currency)}${parseFloat(booking?.info?.merchant_fees).toFixed(2)}`}
              </Text>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0',
              }}
            >
              <Text
                style={{}}
                sx={() => ({
                  fontSize: 30,
                  lineHeight: 1,
                  color: '#ededed',
                  '@media (maxWidth: 480px)': {
                    fontSize: 22,
                  },
                })}
              >
                {`GST(${getFeeFromBookingAmount(booking?.info?.merchant_gst, booking?.booking_amount)}%)`}
              </Text>
              <Text
                style={{
                  fontSize: 28,
                  lineHeight: 1,
                  color: '#b42b2d',
                }}
              >
                {`${getCurrencyText(complex?.info?.currency)}${parseFloat(booking?.info?.merchant_gst).toFixed(2)}`}
              </Text>
            </Box>

            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0',
              }}
            >
              <Text
                style={{}}
                sx={() => ({
                  fontSize: 30,
                  lineHeight: 1,
                  color: '#ededed',
                  '@media (maxWidth: 480px)': {
                    fontSize: 22,
                  },
                })}
              >
                Total Amount
              </Text>
              <Text
                sx={() => ({
                  fontSize: 28,
                  lineHeight: 1,
                  color: '#b42b2d',
                  '@media (max-width: 480px)': {
                    fontSize: 21,
                  },
                })}
              >
                {`${getCurrencyText(complex?.info?.currency)}${parseFloat(
                  Number(booking?.final_amount) +
                    Number(booking?.info?.merchant_gst) +
                    Number(booking?.info?.merchant_fees) +
                    Number(0)
                ).toFixed(2)}`}
              </Text>
            </Box>
          </Box>
          <Text
            sx={() => ({
              fontStyle: 'italic',
              lineHeight: 1.4,
              letterSpacing: '1px',
              color: '#c1c1c1',
              marginTop: 30,
              fontSize: 25,
              '@media (max-width: 480px)': {
                fontSize: 20,
              },
            })}
          >
            Please double check your booking details. No refund or cancellation allowed after booking is made.
          </Text>
          <Box
            sx={() => ({
              background: '#1c2030',
              padding: 40,
              borderRadius: 15,
              marginTop: 30,
              '@media (max-width: 480px)': {
                padding: 15,
              },
            })}
          >
            <Title
              order={6}
              sx={() => ({
                fontSize: 33,
                lineHeight: 0.83,
                color: '#ededed',
                marginBottom: 20,
                fontWeight: 400,
                '@media (max-width: 480px)': {
                  fontSize: 28,
                },
              })}
            >
              User detail
            </Title>
            <form onSubmit={(event) => handleSubmit(event)}>
              <InputWrapper
                sx={() => ({
                  label: {
                    fontSize: 22,
                    '@media (max-width: 480px)': {
                      fontSize: 18,
                    },
                  },
                })}
                label="User Name*"
              >
                <Input
                  value={username}
                  disabled
                  // onChange={(e) => handleUsernameChange(e)}
                  variant="filled"
                  placeholder="Enter name"
                  sx={() => ({
                    border: 'solid 1px #585858',
                    borderRadius: 10,
                    color: '#c1c1c1',
                    fontSize: '20px',
                    input: {
                      fontSize: '20px',
                    },
                  })}
                />
              </InputWrapper>
              <InputWrapper
                sx={() => ({
                  marginTop: 30,
                  label: {
                    fontSize: 22,
                    '@media (max-width: 480px)': {
                      fontSize: 18,
                    },
                  },
                })}
                label="Phone Number *"
              >
                {/* <Box> */}
                <Select
                  sx={() => ({
                    marginBottom: 10,
                    maxWidth: 120,
                    select: {
                      fontSize: 16,
                    },
                  })}
                  placeholder="+60"
                  rightSection={<ChevronDown size={22} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  onChange={(value) => {
                    setCountryCode(value);
                  }}
                  data={[
                    { value: '+60', label: '+60' },
                    { value: '+65', label: '+65' },
                  ]}
                />
                {/* </Box> */}
                <Input
                  value={phoneNumber}
                  onChange={(e) => handlePhoneNumber(e)}
                  variant="filled"
                  placeholder="Phone number"
                  sx={() => ({
                    border: 'solid 1px #585858',
                    borderRadius: 10,
                    color: '#c1c1c1',
                    input: {
                      fontSize: '20px',
                    },
                  })}
                />
              </InputWrapper>
              <InputWrapper
                sx={() => ({
                  marginTop: 30,
                  label: {
                    fontSize: 22,
                    '@media (max-width: 480px)': {
                      fontSize: 18,
                    },
                  },
                })}
                label="Email*"
              >
                <Input
                  value={email}
                  onChange={(e) => handleEmail(e)}
                  variant="filled"
                  placeholder="Email address"
                  type="email"
                  sx={() => ({
                    border: 'solid 1px #585858',
                    borderRadius: 10,
                    color: '#c1c1c1',
                    input: {
                      fontSize: '20px',
                    },
                  })}
                />
              </InputWrapper>
              {emailNotValidated && (
                <Text
                  style={{
                    fontSize: '20px',
                    fontWeight: '300',
                    fontStyle: 'italic',
                    lineHeight: 1.56,
                    color: '#d43d40',
                    marginTop: 20,
                  }}
                >
                  *Please enter a valid email to <br />
                  receive your e-receipt.
                </Text>
              )}
              {notValidated && (
                <Text
                  style={{
                    fontSize: '20px',
                    fontWeight: '300',
                    fontStyle: 'italic',
                    lineHeight: 1.56,
                    color: '#d43d40',
                    marginTop: 20,
                  }}
                >
                  *Please enter a valid Phone Number
                </Text>
              )}
              {usernameNotValidated && (
                <Text
                  style={{
                    fontSize: '20px',
                    fontWeight: '300',
                    fontStyle: 'italic',
                    lineHeight: 1.56,
                    color: '#d43d40',
                    marginTop: 20,
                  }}
                >
                  *Please enter your name
                </Text>
              )}

              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '30px 0',
                }}
              >
                <Text
                  sx={() => ({
                    fontSize: 30,
                    lineHeight: 1,
                    color: '#ededed',
                    '@media (max-width: 480px)': {
                      fontSize: 22,
                    },
                  })}
                >
                  Total Amount
                </Text>
                <Text
                  sx={() => ({
                    fontSize: '28px',
                    lineHeight: 1,
                    color: '#b42b2d',
                    '@media (max-width: 480px)': {
                      fontSize: 22,
                    },
                  })}
                >
                  {`${getCurrencyText(complex?.info?.currency)}${parseFloat(
                    Number(booking?.final_amount) +
                      Number(booking?.info?.merchant_gst) +
                      Number(booking?.info?.merchant_fees) +
                      Number(0)
                  ).toFixed(2)}`}
                </Text>
              </Box>
              {isLockedBooking && (
                <Button
                  type="submit"
                  style={{
                    width: '100%',
                    height: '50px',
                    padding: '0 30px',
                    borderRadius: '10px',
                    backgroundColor: '#b42b2d',
                    fontSize: '20px',
                    fontWeight: 500,
                    lineHeight: 1.04,
                    letterSpacing: '0.25px',
                  }}
                >
                  Pay Now
                </Button>
              )}
            </form>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default Booking;
