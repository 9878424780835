import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Box, Title, Button } from '@mantine/core';

import { useLocation, useNavigate, Link } from 'react-router-dom';

// API-Service
import { paymentSuccessErrorService } from '../../service/API.service';

// Components
import PageTitle from '../../components/PageTitle/PageTitle';
import Loader from '../../components/Loader/Loader';

const sampleResponse = {
  booking_id: '209644',
  status: '1',
  transaction_id: '12578',
};

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    desc,
    refNo,
    remark,
    price,
    paymentMethod,
    sports_facility_id,
    court_nos,
    date,
    start,
    no_of_hours,
    total_expected_cost,
    sports_name,
    sports_thumb,
    sports_end_time,
    sports_start_time,
    sports_start_bookingAmPm,
    phoneNumber,
    uuid,
    facilitiesTitle,
    courts,
    timeSlots,
    id,
    isLockedBookingValue,
    paymentUrl,
  } = location.state;

  // const [paymentRespons, setPaymentRespons] = useState(null)

  // const paymentVerify = async(respons) => {
  //     const result = await paymentSuccessErrorService({
  //       booking_id: respons?.transactionId,
  //       status: respons?.status,
  //       transaction_id: respons?.transactionId
  //     });
  //     if (result?.data?.success) {
  //       navigate('/success', {
  //         state: {
  //           court_nos,
  //           date,
  //           total_expected_cost,
  //           sports_start_time,
  //           sports_start_bookingAmPm,
  //           sports_end_time,
  //         },
  //       });
  //     } else {
  //       navigate('/error', {
  //         state: {
  //           sports_error_msg: result?.error?.title,
  //           sports_facility_id,
  //           court_nos,
  //           date,
  //           start,
  //           no_of_hours,
  //           total_expected_cost,
  //           sports_name,
  //           sports_thumb,
  //           sports_end_time,
  //           sports_start_time,
  //           sports_start_bookingAmPm,
  //           phoneNumber,
  //           uuid,
  //           facilitiesTitle,
  //           courts,
  //           timeSlots,
  //           id,
  //           isLockedBookingValue
  //         },
  //       });
  //     }
  // }

  // const finished = (e) => {
  //   console.log("Finished funcation working!", e.target)
  // }

  // useEffect(()=>{
  //   // document.addEventListener('onafterscriptexecute', finished, true);
  //   document.onafterscriptexecute = finished;
  // },[])

  // useEffect( async ()=>{
  //   if(paymentRespons){
  //    const result = await paymentSuccessErrorService({
  //       booking_id: paymentRespons?.transactionId,
  //       status: paymentRespons?.status,
  //       transaction_id: paymentRespons?.transactionId
  //     });
  //     if (result?.data?.success) {
  //       navigate('/success', {
  //         state: {
  //           court_nos,
  //           date,
  //           total_expected_cost,
  //           sports_start_time,
  //           sports_start_bookingAmPm,
  //           sports_end_time,
  //         },
  //       });
  //     } else {
  //       navigate('/error', {
  //         state: {
  //           sports_error_msg: result?.error?.title,
  //           sports_facility_id,
  //           court_nos,
  //           date,
  //           start,
  //           no_of_hours,
  //           total_expected_cost,
  //           sports_name,
  //           sports_thumb,
  //           sports_end_time,
  //           sports_start_time,
  //           sports_start_bookingAmPm,
  //           phoneNumber,
  //           uuid,
  //           facilitiesTitle,
  //           courts,
  //           timeSlots,
  //           id,
  //           isLockedBookingValue
  //         },
  //       });
  //     }
  //   }
  // },[paymentRespons])

  useEffect(async () => {
    if (desc && refNo && remark && price && paymentMethod && !paymentUrl) {
      try {
        window?.kioskVEMPayment(desc, refNo, remark, price, paymentMethod);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    } else if (paymentUrl) {
      window.open(paymentUrl, '_self');
    }
  }, [desc, refNo, remark, price, paymentMethod]);

  return (
    <Container size="md" px="md">
      <PageTitle uuid={uuid} />
      {/* <Helmet>
        <script type="text/javascript">
          {`${}`}
        </script>
      </Helmet> */}
      <Box
        style={{
          marginTop: 100,
        }}
      >
        <Loader />
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 30,
        }}
      >
        <Title
          order={6}
          style={{
            fontSize: 30,
            fontWeight: 500,
            lineHeight: '50px',
            textAlign: 'center',
          }}
        >
          Payment is processing.
          <br />
          Please wait for the confirmation.
        </Title>
      </Box>
      <Button
        sx={() => ({
          padding: '15px 50px',
          margin: '60px auto 35px auto',
          minHeight: 55,
          fontSize: '22px',
          fontWeight: 600,
          lineHeight: 1.39,
          textAlign: 'center',
          color: '#ededed',
          borderRadius: '10',
          display: 'flex',
          backgroundColor: '#b42b2d',
          border: 'none',
          '@media only screen and (max-width: 480px)': {
            margin: '30px auto 0 auto',
          },
          '&:hover': {
            backgroundColor: '#b42b2d',
          },
          a: {
            textDecoration: 'none',
            color: '#ededed',
          },
        })}
        onClick={() => window?.kioskVEHome()}
      >
        <Link to="/" state={{ machineUUID: uuid }}>
          Cancel Payment
        </Link>
      </Button>
    </Container>
  );
};

export default Payment;
