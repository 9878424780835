import axios from 'axios';

const url = process.env.REACT_APP_APP_API_BACKEND_SERVER;

export const facilitiesListService = (uuid, isKiosk, isQR) => {
  let headers = { uuid };
  if (isQR) {
    headers = {
      auth: uuid,
    };
  }
  return axios.get(`${url}/kiosk/sports_facility`, {
    headers,
  });
};

export const complexBookingService = ({ id, date, uuid }) => {
  const isAuth = localStorage.getItem('isAuth');
  let headers = { uuid };
  if (isAuth === true) {
    headers = {
      auth: uuid,
    };
  }
  return axios.post(
    `${url}/kiosk/booking`,
    { sports_facility_id: id, date },
    {
      headers,
    }
  );
};

// get courts api
export const checkAvailabilityService = async ({ sports_facility_id, date, start, no_of_hours, uuid }) => {
  try {
    const isAuth = localStorage.getItem('isAuth');
    let headers = { uuid };
    if (isAuth === true)
      headers = {
        auth: uuid,
      };
    const result = await axios.post(
      `${url}/kiosk/booking/get-courts`,
      {
        sports_facility_id,
        date,
        start,
        no_of_hours,
      },
      {
        headers,
      }
    );
    return result;
  } catch (err) {
    const { response } = err;
    return response?.data;
  }
};

export const checkAvailabilityService2 = async ({ sports_facility_id, date, start, no_of_hours, court_nos, uuid }) => {
  try {
    const isAuth = localStorage.getItem('isAuth');
    let headers = { uuid };
    if (isAuth === true)
      headers = {
        auth: uuid,
      };
    const result = await axios.post(
      `${url}/kiosk/booking/check`,
      {
        sports_facility_id,
        date,
        start,
        no_of_hours,
        type: 'walkin',
        court_nos,
        dynamic_discount: null,
      },
      {
        headers,
      }
    );
    return result;
  } catch (err) {
    const { response } = err;
    return response?.data;
  }
};

export const bookingLockedService = async ({ sports_facility_id, court_nos, date, start, no_of_hours, uuid }) => {
  try {
    const isAuth = localStorage.getItem('isAuth');
    let headers = { uuid };
    if (isAuth)
      headers = {
        auth: uuid,
      };
    const result = await axios.post(
      `${url}/api/v1/sports-complex/booking/lock-slot`,
      {
        sports_facility_id,
        type: 'walkin',
        court_nos,
        date,
        start,
        no_of_hours,
        dynamic_discount: null,
      },
      {
        headers,
      }
    );
    return result;
  } catch (err) {
    const { response } = err;
    return response?.data;
  }
};

export const bookingService = async ({
  sports_facility_id,
  court_nos,
  date,
  start,
  no_of_hours,
  user_name,
  user_email,
  user_phone,
  uuid,
}) => {
  try {
    const isAuth = localStorage.getItem('isAuth');
    let headers = { uuid };
    if (isAuth === true)
      headers = {
        auth: uuid,
      };
    const result = await axios.post(
      `${url}/kiosk/booking/add`,
      {
        sports_facility_id,
        type: 'walkin',
        court_nos,
        date,
        start,
        no_of_hours,
        dynamic_discount: null,
        user_name,
        user_email,
        user_phone,
      },
      {
        headers,
      }
    );
    return result;
  } catch (err) {
    const { response } = err;
    return response?.data;
  }
};

export const paymentSuccessErrorService = async ({ booking_id, status, transaction_id, uuid }) => {
  try {
    const isAuth = localStorage.getItem('isAuth');
    let headers = { uuid };
    if (isAuth === true)
      headers = {
        auth: uuid,
      };
    const result = await axios.post(
      `${url}/api/v1/vechnology/booking`,
      {
        booking_id,
        status,
        transaction_id,
      },
      {
        headers,
      }
    );
    return result;
  } catch (err) {
    const { response } = err;
    return response?.data;
  }
};
